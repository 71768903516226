<template>
  <page-header-wrapper>
    <div class="a-card">
      <!-- <CouponTypeSearch @handleAdd='handleAdd' :queryParam="queryParam" /> -->
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd()">{{ $t('button.create') }}</a-button>
        <a-button type="dashed" style="display:none" @click="tableOption"
          >{{ (optionAlertShow && '关闭') || '开启' }} alert
        </a-button>
        <a-dropdown v-action:edit v-if="selectedRowKeys.length > 0">
          <a-menu slot="overlay">
            <a-menu-item class="hide" key="1" click="handleDeleteSelected">
              <a-icon type="delete" />
              删除
            </a-menu-item>
            <!-- lock | unlock -->
          </a-menu>
          <a-button style="margin-left: 8px">
            批量操作
            <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :dataSource="data"
        :data="loadData"
        :alert="options.alert"
        :rowSelection="options.rowSelection"
        :pagination="{ showSizeChanger: true, showQuickJumper: true, pageSize: 20, total: 50 }"
        :row-key="record => record.couponTypeId"
      >
        <!-- 优惠券类型编码 -->
        <a-table-column :title="$t('columnName.couponTypeCode')" align="left">
          <template slot-scope="record">{{record.couponTypeCode}}</template>
        </a-table-column>
        <!-- 优惠券类型名称 -->
        <a-table-column :title="$t('columnName.couponTypeName')" align="left">
          <template slot-scope="record">{{record.couponTypeName}}</template>
        </a-table-column>
        <!-- 优惠券条件类型 -->
        <a-table-column :title="$t('columnName.marketingConditionType')" align="left">
          <template slot-scope="record">{{getCode('marketingConditionTypeValue', record.marketingConditionType)}}</template>
        </a-table-column>
        <!-- 优惠券类型 -->
        <a-table-column :title="$t('columnName.marketingDiscountType')" align="left">
          <template slot-scope="record">{{getCode('marketingDiscountTypeValue', record.marketingDiscountType)}}</template>
        </a-table-column>
      </s-table>

      <CouponTypeEdit ref="couponTypeEdit" />
      <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk" />
    </div>
  </page-header-wrapper>
</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import {
  deleteCouponType,
  disableCouponType,
  enableCouponType,
  getCouponTypeData,
  searchCouponType
} from '@/api/marketing/couponType'
import moment from 'moment'
import { STable } from '@/components'

// import CouponTypeSearch from '@/views/marketing/CouponTypeSearch'
import CouponTypeEdit from '@/views/marketing/components/CouponTypeEdit'
import CommentEditor from '@/components/comment/CommentEditor'

export default {
  name: 'CouponTypeList',
  components: {
    STable,
    CouponTypeEdit,
    CommentEditor
  },
  data() {
    return {
      confirmEditLoading: false,
      // 查询参数
      queryParam: {},
      data: [],
      status: 'all',
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getCouponType(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created() {
    this.tableOption()
    // getRoleList({t: new Date()})
  },
  mounted() {},
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      return searchCouponType(Object.assign(parameter, this.queryParam)).then(res => {
        console.log('loadData->searchCouponType.parameter', parameter, res)
        return res
      })
    },
    tableOption() {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },

    handleAdd() {
      this.$refs.couponTypeEdit.add()
    },

    handleEdit(record) {
      const that = this
      getCouponTypeData({ couponTypeId: record.couponTypeId })
        .then(res => {
          this.$refs.couponTypeEdit.edit(res)
        })
        .catch(res => {
          that.$message.error(this.$t('entity.couponType.get.fail'))
        })
      // 弹出编辑页面
      this.couponTypeModalShow = true
    },

    handleDelete(record) {
      const that = this
      this.$confirm(this.$t('message.confirm.delete'), this.$t('message.information'), {
        customClass: 'message_wrap',
        closeOnClickModal: false
      }).then(function() {
        return deleteCouponType(record)
          .then(res => {
            console.log('handleDelete->delete{domain_name}', record, res)
            that.$message.success(this.$t('entity.couponType.delete.success'))
            return res
          })
          .catch(() => {
            that.$message.error(this.$t('entity.couponType.delete.fail'))
          })
      })
    },
    handleCommentEditOk(params) {
      const record = params.record
      const that = this
      if (params.type === 'disable') {
        return disableCouponType(record)
          .then(res => {
            console.log('handleDisable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      } else if (params.type === 'enable') {
        return enableCouponType(record)
          .then(res => {
            console.log('handleEnable', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('解除锁定成功')
            return res
          })
          .catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('解锁失败')
          })
      }
    },
    handleDisable(record) {
      this.$refs['commentEditor'].show(record, 'disable')
    },
    handleEnable(record) {
      this.$refs['commentEditor'].show(record, 'enable')
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>

<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <a-modal
    width="50%"
    :title="$t('page.couponType')"
    :visible="couponTypeModalShow"
    :confirm-loading="confirmEditLoading"
    @ok="handleEditOk"
    @cancel="handleEditCancel"
  >
    <div>
      <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
        <a-form
          :form="form"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-form-item
            :label="$t('field.couponTypeCode')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'couponTypeCode',
                {
                  rules: $rules([
                    { required: true, message: '请输入' + this.$t('field.couponTypeCode') },
                    {
                      type: 'checkExists',
                      url: '/api/marketing/couponType/checkCouponTypeCodeExists',
                      existIdValue: couponTypeData.couponTypeId,
                      checkField: 'couponTypeCode',
                      idFieldName: 'couponTypeId',
                      tableName: 'couponType'
                    },
                    { len: 4, message: this.$t('field.couponTypeCode') + '长度需为4' }
                  ])
                }
              ]"
              name="couponTypeData.couponTypeCode"
              v-model="couponTypeData.couponTypeCode"
              :placeholder="'请输入' + this.$t('field.couponTypeCode')"
              :maxLength="4"
            />
          </a-form-item>
          <a-form-item
            :label="$t('field.couponTypeName')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-decorator="[
                'couponTypeName',
                {
                  rules: $rules([
                    { required: true, message: '请输入' + this.$t('field.couponTypeName') },
                    {
                      type: 'checkExists',
                      url: '/api/marketing/couponType/checkCouponTypeNameExists',
                      existIdValue: couponTypeData.couponTypeId,
                      checkField: 'couponTypeName',
                      idFieldName: 'couponTypeId',
                      tableName: 'couponType'
                    },
                    { max: 100, message: this.$t('field.couponTypeName') + '长度小于等于100' }
                  ])
                }
              ]"
              name="couponTypeData.couponTypeName"
              v-model="couponTypeData.couponTypeName"
              :placeholder="'请输入' + this.$t('field.couponTypeName')"
            />
          </a-form-item>
          <a-form-item
            :label="$t('field.marketingConditionType')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select
              mode="default"
              v-decorator="[
                'marketingConditionType',
                {
                  rules: [{ required: true, message: '请输入' + this.$t('field.marketingConditionType') }]
                }
              ]"
              v-model="couponTypeData.marketingConditionType"
            >
              <a-select-option
                v-for="(item, index) in codeList.couponMarketingConditionType"
                :key="index"
                :value="item.label"
                >[{{ item.value }}] {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :label="$t('field.marketingDiscountType')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select
              mode="default"
              v-decorator="[
                'marketingDiscountType',
                {
                  rules: [{ required: true, message: '请输入' + this.$t('field.marketingDiscountType') }]
                }
              ]"
              v-model="couponTypeData.marketingDiscountType"
            >
              <a-select-option v-for="(item, index) in codeList.marketingDiscountType" :key="index" :value="item.label"
                >[{{ item.value }}] {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-card>
    </div>
  </a-modal>
</template>

<script>
import { saveCouponTypeData } from '@/api/marketing/couponType'

export default {
  name: 'EditCouponType',
  props: {},
  data() {
    return {
      confirmEditLoading: false,
      form: this.$form.createForm(this),
      couponTypeModalShow: false,
      couponTypeData: {},
      codeList: {
        isValid: []
      }
    }
  },
  methods: {
    createForm() {
      let that = this
      this.$nextTick(() => {
        const formData = {}
        Object.keys(that.form.getFieldsValue()).map(key => (formData[key] = this.couponTypeData[key]))
        that.form.setFieldsValue(formData)
        console.log('初始化form', that.form)
      })
    },

    add() {
      this.couponTypeData = {}
      this.createForm()
      this.couponTypeModalShow = true
    },
    edit(data) {
      this.couponTypeData = data
      this.createForm()
      this.couponTypeModalShow = true
    },
    handleEditCancel() {
      this.couponTypeModalShow = false
    },
    handleEditOk() {
      const that = this
      this.form.validateFieldsAndScroll((err, values) => {
        console.log('validateFieldsAndScroll', err, values)
        if (err && Object.keys(err).length > 0) {
          this.alertError(err)
          return
        }
        Object.assign({ ...that.couponTypeData, ...that.form.getFieldsValue() })
        saveCouponTypeData(this.couponTypeData)
          .then(res => {
            this.couponTypeData = {}
            // 弹出编辑页面
            this.couponTypeModalShow = false
            that.$message.success(this.$t('entity.couponType.save.success'))
            this.$parent.$parent.$refs.table.refresh(true)
          })
          .catch(res => {
            that.$message.error(this.$t('entity.couponType.save.fail'))
          })
      })
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>

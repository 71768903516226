var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{attrs:{"width":"50%","title":_vm.$t('page.couponType'),"visible":_vm.couponTypeModalShow,"confirm-loading":_vm.confirmEditLoading},on:{"ok":_vm.handleEditOk,"cancel":_vm.handleEditCancel}},[_c('div',[_c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form',{attrs:{"form":_vm.form,"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-form-item',{attrs:{"label":_vm.$t('field.couponTypeCode'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'couponTypeCode',
              {
                rules: _vm.$rules([
                  { required: true, message: '请输入' + this.$t('field.couponTypeCode') },
                  {
                    type: 'checkExists',
                    url: '/api/marketing/couponType/checkCouponTypeCodeExists',
                    existIdValue: _vm.couponTypeData.couponTypeId,
                    checkField: 'couponTypeCode',
                    idFieldName: 'couponTypeId',
                    tableName: 'couponType'
                  },
                  { len: 4, message: this.$t('field.couponTypeCode') + '长度需为4' }
                ])
              }
            ]),expression:"[\n              'couponTypeCode',\n              {\n                rules: $rules([\n                  { required: true, message: '请输入' + this.$t('field.couponTypeCode') },\n                  {\n                    type: 'checkExists',\n                    url: '/api/marketing/couponType/checkCouponTypeCodeExists',\n                    existIdValue: couponTypeData.couponTypeId,\n                    checkField: 'couponTypeCode',\n                    idFieldName: 'couponTypeId',\n                    tableName: 'couponType'\n                  },\n                  { len: 4, message: this.$t('field.couponTypeCode') + '长度需为4' }\n                ])\n              }\n            ]"}],attrs:{"name":"couponTypeData.couponTypeCode","placeholder":'请输入' + this.$t('field.couponTypeCode'),"maxLength":4},model:{value:(_vm.couponTypeData.couponTypeCode),callback:function ($$v) {_vm.$set(_vm.couponTypeData, "couponTypeCode", $$v)},expression:"couponTypeData.couponTypeCode"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.couponTypeName'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'couponTypeName',
              {
                rules: _vm.$rules([
                  { required: true, message: '请输入' + this.$t('field.couponTypeName') },
                  {
                    type: 'checkExists',
                    url: '/api/marketing/couponType/checkCouponTypeNameExists',
                    existIdValue: _vm.couponTypeData.couponTypeId,
                    checkField: 'couponTypeName',
                    idFieldName: 'couponTypeId',
                    tableName: 'couponType'
                  },
                  { max: 100, message: this.$t('field.couponTypeName') + '长度小于等于100' }
                ])
              }
            ]),expression:"[\n              'couponTypeName',\n              {\n                rules: $rules([\n                  { required: true, message: '请输入' + this.$t('field.couponTypeName') },\n                  {\n                    type: 'checkExists',\n                    url: '/api/marketing/couponType/checkCouponTypeNameExists',\n                    existIdValue: couponTypeData.couponTypeId,\n                    checkField: 'couponTypeName',\n                    idFieldName: 'couponTypeId',\n                    tableName: 'couponType'\n                  },\n                  { max: 100, message: this.$t('field.couponTypeName') + '长度小于等于100' }\n                ])\n              }\n            ]"}],attrs:{"name":"couponTypeData.couponTypeName","placeholder":'请输入' + this.$t('field.couponTypeName')},model:{value:(_vm.couponTypeData.couponTypeName),callback:function ($$v) {_vm.$set(_vm.couponTypeData, "couponTypeName", $$v)},expression:"couponTypeData.couponTypeName"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.marketingConditionType'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'marketingConditionType',
              {
                rules: [{ required: true, message: '请输入' + this.$t('field.marketingConditionType') }]
              }
            ]),expression:"[\n              'marketingConditionType',\n              {\n                rules: [{ required: true, message: '请输入' + this.$t('field.marketingConditionType') }]\n              }\n            ]"}],attrs:{"mode":"default"},model:{value:(_vm.couponTypeData.marketingConditionType),callback:function ($$v) {_vm.$set(_vm.couponTypeData, "marketingConditionType", $$v)},expression:"couponTypeData.marketingConditionType"}},_vm._l((_vm.codeList.couponMarketingConditionType),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.label}},[_vm._v("["+_vm._s(item.value)+"] "+_vm._s(item.label)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":_vm.$t('field.marketingDiscountType'),"labelCol":{ lg: { span: 7 }, sm: { span: 7 } },"wrapperCol":{ lg: { span: 10 }, sm: { span: 17 } }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'marketingDiscountType',
              {
                rules: [{ required: true, message: '请输入' + this.$t('field.marketingDiscountType') }]
              }
            ]),expression:"[\n              'marketingDiscountType',\n              {\n                rules: [{ required: true, message: '请输入' + this.$t('field.marketingDiscountType') }]\n              }\n            ]"}],attrs:{"mode":"default"},model:{value:(_vm.couponTypeData.marketingDiscountType),callback:function ($$v) {_vm.$set(_vm.couponTypeData, "marketingDiscountType", $$v)},expression:"couponTypeData.marketingDiscountType"}},_vm._l((_vm.codeList.marketingDiscountType),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.label}},[_vm._v("["+_vm._s(item.value)+"] "+_vm._s(item.label)+" ")])}),1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }